<template>
        <div>
                <GoogleTagManager />
                <slot />
        </div>
</template>

<script setup>
import { init as initApp } from "~/assets/js/landing_page/app.js"
import { init as initScroll } from "~/assets/js/landing_page/scroll.js"

import layoutStyle from "~/assets/styles/landing_page/app.scss?inline"

let googleAnalytics = []
if (process.env.NODE_ENV === "production") {
        googleAnalytics.push({ src: "https://www.googletagmanager.com/gtag/js?id=UA-126712929-1", async: true, },)
        googleAnalytics.push(`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'UA-126712929-1');
        `)
}
useHead({
        style: [layoutStyle],
        script: [...googleAnalytics],
})

onMounted(() => {
        initApp()
        initScroll()
})
</script>
