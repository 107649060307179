export function init() {
        $(document).ready(function() {

                // Activate Animation 
                AOS.init({
                        once: true // Only Once
                })

                // Menu Dropdown

                $('.burger').on('click', function() {
                        $(this).toggleClass('open');
                        $('.main-menu').toggleClass('open');
                })


                // Play Video
                $('#play-demo').on('click', function() {

                        var vid = document.getElementById("demo");

                        vid.play();
                        $(this).hide('slow');

                })

                // Fixed Header

                $(window).scroll(function() {
                        fixedheader.call(this);
                })

                var header = $('.header-content');

                function fixedheader() {
                        if ($(this).scrollTop() >= 100) {
                                header.addClass("header-fixed");
                        } else {
                                header.removeClass("header-fixed");
                        }
                }


                // On KEYPRESS

                // Mobile Field
                $('body').on('keypress', '#mobile, #inquiry-mobile', function(e) {

                        var regex = new RegExp("^[0-9\n\r\n!\b]+$");
                        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                        if (regex.test(str)) {
                                return true;
                        }
                        e.preventDefault();
                        return false;
                });

        })
        window.enableSubmitInquiryButton = function() {
                $(".inquiry-form-submit-button").css({ opacity: 1 })
                $(".inquiry-form-submit-button").attr("disabled", false);
        }

        window.disableSubmitInquiryButton = function() {
                $(".inquiry-form-submit-button").css({ opacity: 0.4 })
                $(".inquiry-form-submit-button").attr("disabled", true);
        }
        $(function() {
                disableSubmitInquiryButton()
        })

        // Added for Terms and Conditions
}
